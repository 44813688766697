import { FieldSkeleton, Flex } from '@appliedsystems/applied-design-system';
import React from 'react';
import classes from './CheckoutContainer.module.scss';

export const CheckoutContainerSkeleton = () => {
  return (
    <>
      <Flex className={classes.flexContainer}>
        <div className={classes.flexGrowOne}>
          <FieldSkeleton />
        </div>
        <div className={classes.flexGrowOne}>
          <FieldSkeleton />
        </div>
      </Flex>
      <Flex className={classes.flexContainer}>
        <div className={classes.flexGrowOne}>
          <FieldSkeleton />
        </div>
        <div className={classes.flexGrowOne}>
          <FieldSkeleton />
        </div>
      </Flex>
      <Flex className="flex-align-end mt-150">
        <FieldSkeleton />
      </Flex>
    </>
  );
};
