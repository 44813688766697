import {
  ADSChangeEvent,
  Button,
  Checkbox,
  Col,
  Flex,
  Modal,
  ModalBody,
  ModalFooter,
  PhoneField,
  Row,
  TextField,
} from '@appliedsystems/applied-design-system';
import { PhoneFieldValue } from '@appliedsystems/applied-design-system/dist/components';
import { UnsignedPfa } from '@appliedsystems/payments-core';
import React, { useCallback, useState } from 'react';
import { ApiClient } from '../../../src/api/ApiClient';
import { usePaymentsTranslation } from '../../../src/hooks/usePaymentsTranslation';
import { useAgencyDetailsStore } from '../../../src/store/AgencyDetail';
import { useHppDataStore } from '../HostedPaymentPageContainer/useHppData';
import classes from './ESignModal.module.scss'; // Import the CSS module

type Props = {
  isOpenEsignModal: boolean;
  onClose: () => void;
  base64Pfa: UnsignedPfa;
  isPfaSigned: boolean;
  setIsPfaSigned: (isSigned: boolean) => void;
  signeeName: string;
  signeePhoneNumber: string;
};

export const AddESignModal = ({
  isOpenEsignModal,
  onClose,
  base64Pfa,
  isPfaSigned,
  setIsPfaSigned,
  signeeName,
  signeePhoneNumber,
}: Props) => {
  const { t } = usePaymentsTranslation();

  // State management for modal values
  const [nameModalValue, setModalNameValue] = useState<string>(signeeName);
  const [phoneModalValue, setModalPhoneValue] = useState<PhoneFieldValue>({ phoneNumber: signeePhoneNumber });
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const { hppData, setHppData } = useHppDataStore();
  const agencyDetails = useAgencyDetailsStore();
  const token = agencyDetails.data?.token;

  const handleChangePhone = useCallback((e) => {
    const phoneValue = e.target.value as PhoneFieldValue;
    setModalPhoneValue(phoneValue);
  }, []);

  const handleSignedClick = async () => {
    setIsPfaSigned(true);

    let signedBase64Pfa = '';

    if (hppData.pf?.quote) {
      const response = await ApiClient.getInstance(token).signPfa({
        pfQuoteId: hppData.pf.quote.quoteId,
        unsignedPfa: hppData.pf.quote.base64Pfa,
        insuredName: nameModalValue,
      });
      if (response.status === 'ok' && response.data) {
        signedBase64Pfa = response.data.signedPfa;
      }
    }

    setHppData({
      pf: {
        ...hppData.pf,
        ...(hppData.pf?.quote?.quoteId && {
          signeeInfo: {
            name: nameModalValue,
            phoneNumber: phoneModalValue.E164Number || '',
            timeStamp: currentTime,
            ...(signedBase64Pfa && { signedBase64Pfa }),
          },
        }),
      },
    });

    onClose();
  };

  if (!hppData.pf?.quote) {
    return <></>;
  }

  // Checkbox change handler
  const handleCheckboxChange = (e: ADSChangeEvent) => {
    setIsChecked(e.target.value);
  };

  const currentTime = new Date().toISOString();

  return (
    <Modal onClose={onClose} title={t('REVIEW_PREMIUM_FINANCE')} open={isOpenEsignModal}>
      <ModalBody>
        <div className={classes.pdfViewer}>
          <object
            data={`data:application/pdf;base64,${base64Pfa}#zoom=100&scrollbar=0&toolbar=1&navpanes=0`}
            type="text/html"
            width="100%"
            className={classes.pdfViewer}
          >
            <p>No PDF plugin detected.</p>
          </object>
        </div>

        <div className={classes.modalText}>
          <Checkbox
            name="assertPFAgreement"
            value={isChecked || isPfaSigned}
            onChange={handleCheckboxChange}
            type="reverse"
            disabled={isPfaSigned}
          >
            {t('ASSERT_PF_AGREEMENT')}
          </Checkbox>
        </div>

        <Flex className={classes.modalText}>
          <p>{t('ENTER_NAME_NUMBER')}</p>
          <Row>
            <Col xs={12} sm={6}>
              <div>
                <TextField
                  label={t('NAME')}
                  value={nameModalValue}
                  onChange={(e) => setModalNameValue(e.target.value)}
                  disabled={isPfaSigned}
                  name={'name'}
                />
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div>
                <PhoneField
                  label={t('PHONE_NUMBER')}
                  value={phoneModalValue}
                  onChange={handleChangePhone}
                  disabled={isPfaSigned}
                  name={'phoneNumber'}
                  hideExt
                />
              </div>
            </Col>
          </Row>
        </Flex>
      </ModalBody>

      <ModalFooter>
        <div className={classes.buttonContainer}>
          <Button type="tertiary" onClick={onClose}>
            {t('CANCEL')}
          </Button>
          <Button
            type="primary"
            onClick={handleSignedClick}
            disabled={!(nameModalValue.trim() && phoneModalValue.isValid && isChecked)}
          >
            {t('AGREE_SIGN')}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
