import {
  BrandType,
  Button,
  Checkbox,
  Col,
  Flex,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  SectionTypes,
  SectionV2,
  TextField,
  TextFieldFormats,
  Tooltip,
  Trans,
  useFormContext,
  useNotifications,
} from '@appliedsystems/applied-design-system';
import { CreateQuoteSuccessResponse, premiumFinanceProviderLabels } from '@appliedsystems/payments-core';
import { useMutation } from '@tanstack/react-query';
import { ClipboardEvent, default as React, FC } from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { ApiClient } from '../../api/ApiClient';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { useAgencyDetailsStore } from '../../store/AgencyDetail';
import { useHppDataStore } from '../HostedPaymentPageContainer/useHppData';
import classes from './SetUpPaymentInstallmentsModal.module.scss';

type SetUpPaymentInstallmentsModalFieldsProps = {
  pfQuote: CreateQuoteSuccessResponse;
  onClose: () => void;
  isSubmitting?: boolean;
};

const SetUpPaymentInstallmentsModalFields = ({
  pfQuote,
  onClose,
  isSubmitting,
}: SetUpPaymentInstallmentsModalFieldsProps) => {
  // To access formState, the component need to be inside the Form component
  const {
    formState: { isValid, errors },
    trigger,
  } = useFormContext();
  const { t } = usePaymentsTranslation();
  const [isAchAgreementOpened, setIsAchAgreementOpened] = React.useState(false);

  const preventOnPaste = (event: ClipboardEvent<Element>) => {
    event.preventDefault();
  };

  return (
    <>
      <ModalBody>
        <div className={classes.modalBody}>
          <Flex>
            <SectionV2 title={t('ACCOUNT_INFORMATION')} type={SectionTypes.H3} className="mt-0">
              <span>{t('SET_UP_PAYMENT_INSTALLMENTS_DESCRIPTION')}</span>
            </SectionV2>
            <Row>
              <Col xs={12} sm={10}>
                <TextField name={`accountHolderName`} label={t('ACCOUNT_HOLDER_NAME')} onPaste={preventOnPaste} />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={5}>
                <TextField
                  format={TextFieldFormats.DECIMAL_DIGITS}
                  name={`accountNumber`}
                  label={t('ACCOUNT_NUMBER')}
                  onPaste={preventOnPaste}
                  password
                />
              </Col>
              <Col xs={12} sm={5}>
                <TextField
                  format={TextFieldFormats.DECIMAL_DIGITS}
                  name={`confirmAccountNumber`}
                  label={t('CONFIRM_ACCOUNT_NUMBER')}
                  onPaste={preventOnPaste}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={5}>
                <TextField
                  format={TextFieldFormats.DECIMAL_DIGITS}
                  name={`abaRoutingNumber`}
                  label={t('ABA_ROUTING_NUMBER')}
                  onPaste={preventOnPaste}
                  password
                />
              </Col>
              <Col xs={12} sm={5}>
                <TextField
                  format={TextFieldFormats.DECIMAL_DIGITS}
                  name={`confirmAbaRoutingNumber`}
                  label={t('CONFIRM_ABA_ROUTING_NUMBER')}
                  onPaste={preventOnPaste}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className={classes.checkboxWrapper}>
                <Tooltip
                  content={t('CLICK_AND_REVIEW_TERMS')}
                  disabled={Object.keys(errors).length > 1 || !errors.authorizePaymentInstallments}
                >
                  <Checkbox
                    name="authorizePaymentInstallments"
                    type="reverse"
                    disabled={!isAchAgreementOpened}
                    onChange={() => trigger('authorizePaymentInstallments')} // need to manually trigger this field so that the error state is update to date
                  >
                    <span>
                      <Trans
                        i18nKey="PAYMENT_INSTALLMENTS_AUTHORIZATION_DESCRIPTION_WITH_ACH_AGREEMENT"
                        components={{
                          termsLink: (
                            <Link
                              to={`/flow/assets/ach-agreement-${pfQuote?.company}.pdf`}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={() => setIsAchAgreementOpened(true)}
                            />
                          ),
                        }}
                        values={{ company: pfQuote?.company ? premiumFinanceProviderLabels[pfQuote?.company] : '' }}
                      />
                    </span>
                  </Checkbox>
                </Tooltip>
              </Col>
            </Row>
          </Flex>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button type="tertiary" onClick={onClose}>
          Cancel
        </Button>
        <Tooltip
          content={t('CLICK_AND_REVIEW_TERMS')}
          // only show tooltip if it only has the authorizePaymentInstallments error
          disabled={Object.keys(errors).length > 1 || !errors.authorizePaymentInstallments}
        >
          <Button type="primary" className="ml-100" disabled={!isValid} submit isLoading={isSubmitting}>
            {t('SUBMIT')}
          </Button>
        </Tooltip>
      </ModalFooter>
    </>
  );
};

type SetUpPaymentInstallmentsModalProps = {
  isSetUpPaymentInstallmentsModalOpen: boolean;
  onClose: () => void;
};
export const SetUpPaymentInstallmentsModal: FC<SetUpPaymentInstallmentsModalProps> = ({
  isSetUpPaymentInstallmentsModalOpen,
  onClose,
}) => {
  const { t } = usePaymentsTranslation();
  const { hppData, setHppData } = useHppDataStore();
  const { addNotification } = useNotifications();
  const agencyDetails = useAgencyDetailsStore();
  const token = agencyDetails.data?.token;

  const pfQuote = hppData.pf?.quote;

  const handleAccountInformationSubmit = (values: AccountInformationSchemaType) => {
    setHppData({
      pf: hppData.pf?.quote?.quoteId
        ? {
            ...hppData.pf,
            accountInformation: {
              accountHolderName: values.accountHolderName,
              accountNumber: values.accountNumber,
              abaRoutingNumber: values.abaRoutingNumber,
              authorizePaymentInstallments: values.authorizePaymentInstallments,
            },
          }
        : undefined,
    });
    onClose();
  };

  const makeSchema = () => {
    const { accountHolderName, accountNumber, abaRoutingNumber, authorizePaymentInstallments } =
      hppData.pf?.accountInformation || {};

    return yup.object({
      accountHolderName: yup.string().label(t('ACCOUNT_HOLDER_NAME')).required().default(accountHolderName),
      accountNumber: yup.string().label(t('ACCOUNT_NUMBER')).required().default(accountNumber),
      confirmAccountNumber: yup
        .string()
        .label(t('ACCOUNT_NUMBER'))
        .required()
        .test({
          test: (_, context) => context.parent.accountNumber === context.parent.confirmAccountNumber,
          message: t('ACCOUNT_NUMBER_MISMATCH'),
        })
        .default(accountNumber),
      abaRoutingNumber: yup.string().label(t('ABA_ROUTING_NUMBER')).required().default(abaRoutingNumber),
      confirmAbaRoutingNumber: yup
        .string()
        .label(t('ABA_ROUTING_NUMBER'))
        .required()
        .test({
          test: (_, context) => context.parent.abaRoutingNumber === context.parent.confirmAbaRoutingNumber,
          message: t('ABA_ROUTING_NUMBER_MISMATCH'),
        })
        .default(abaRoutingNumber),
      authorizePaymentInstallments: yup
        .boolean()
        .not([false], t('AUTHORIZE_PAYMENT_INSTALLMENTS_REQUIRED'))
        .default(authorizePaymentInstallments)
        .required(t('AUTHORIZE_PAYMENT_INSTALLMENTS_REQUIRED')),
    });
  };

  type AccountInformationSchemaType = yup.InferType<ReturnType<typeof makeSchema>>;

  const sendBillingInfo = useMutation(
    (values: AccountInformationSchemaType) =>
      ApiClient.getInstance(token)
        .sendBillinginfo({
          pfQuoteId: pfQuote!.quoteId,
          bankAccountNumber: values.accountNumber,
          bankRoutingNumber: values.abaRoutingNumber,
          name: values.accountHolderName,
        })
        .then((response) => {
          if (response.status !== 'ok') throw response;
          if (!response.data?.errors.length) handleAccountInformationSubmit(values);
          else
            response.data.errors.forEach((error) =>
              addNotification({
                content: error,
                type: BrandType.Error,
              }),
            );
        }),
    {
      onError: (err) => {
        console.error('error sending billing info:', err);
        addNotification({ content: t('REFUSED_ERROR_UNKNOWN'), type: BrandType.Error });
      },
    },
  );

  if (!pfQuote) return null;

  return (
    <Modal open={isSetUpPaymentInstallmentsModalOpen} title={t('SET_UP_PAYMENT_INSTALLMENTS')} onClose={onClose}>
      <Form schema={makeSchema()} onSubmit={(values) => sendBillingInfo.mutate(values)}>
        <SetUpPaymentInstallmentsModalFields
          pfQuote={pfQuote}
          onClose={onClose}
          isSubmitting={sendBillingInfo.isLoading}
        />
      </Form>
    </Modal>
  );
};
