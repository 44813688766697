import { Col, Container, Row } from '@appliedsystems/applied-design-system';
import React from 'react';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import classes from './Cancel.module.scss';

export const Cancel = (): React.ReactElement => {
  const { t } = usePaymentsTranslation();

  return (
    <>
      <div className={classes.app}>
        <Container>
          <Row>
            <Col xs={12}>
              <p>{t('PAYMENT_CANCELLED')}</p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
