import { useCallback } from 'react';
import { FullInvoiceGroup } from '../components/HostedPaymentPageContainer/types';
import { useHppDataStore } from '../components/HostedPaymentPageContainer/useHppData';

export const useCheckIfFinanceable = () => {
  const { hppData } = useHppDataStore();

  const checkIfFinanceable = useCallback(
    (invoice: FullInvoiceGroup) => {
      return hppData.pf?.quickQuote?.financeableInvoices.some(
        (financeableInvoice) => financeableInvoice.invoiceNumber === invoice.invoiceNumber,
      );
    },
    [hppData.pf?.quickQuote?.financeableInvoices],
  );

  return { checkIfFinanceable };
};
