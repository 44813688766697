import { useEffect, useState } from 'react';
import { useInputEvent } from './useInputEvent';

export const useSecretCode = (secretCode: string[]) => {
  const [count, setCount] = useState(0);
  const [success, setSuccess] = useState(false);
  const key = useInputEvent();

  useEffect(() => {
    if (key == null) return;
    if (key !== secretCode[count]) {
      setCount(0);
      return;
    }

    setCount(count + 1);
    if (count + 1 === secretCode.length) {
      setSuccess(true);
    }
    // eslint-disable-next-line
  }, [key]);

  return success;
};
