import { Container, Flex } from '@appliedsystems/applied-design-system';
import React from 'react';
import { Header } from '../Header';
import classes from './Layout.module.scss';

interface LayoutProps {
  leftContainer: React.ReactNode;
  rightContainer: React.ReactNode;
  confirmationComponent: JSX.Element;
  showConfirmationComponent: boolean;
  showHeader?: boolean;
}

export const Layout: React.FC<LayoutProps> = ({
  leftContainer,
  rightContainer,
  confirmationComponent,
  showConfirmationComponent,
  showHeader = true,
}) => {
  return (
    <Container className={classes.LayoutContainer}>
      <Flex className="flex-align-center">
        {showHeader && <Header isConfirmationPage={showConfirmationComponent} />}
        {showConfirmationComponent ? (
          confirmationComponent
        ) : (
          <Flex className={classes.flexContainer}>
            <div>{leftContainer}</div>
            <div>{rightContainer}</div>
          </Flex>
        )}
      </Flex>
    </Container>
  );
};
