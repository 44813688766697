import React from 'react';
import { CreateAccountModal } from './CreateAccountModal';
import { LogInModal } from './LogInModal/LogInModal';

type Props = {
  paymentSessionToken: string;
};

export const AccountManagementModals = ({ paymentSessionToken }: Props) => {
  return (
    <>
      <LogInModal paymentSessionToken={paymentSessionToken} />
      <CreateAccountModal paymentSessionToken={paymentSessionToken} />
    </>
  );
};
