import {
  Alert,
  BrandType,
  Button,
  Col,
  Flex,
  ModalBody,
  ModalFooter,
  Row,
  useFormContext,
} from '@appliedsystems/applied-design-system';
import React from 'react';
import { usePaymentsTranslation } from '../../../hooks/usePaymentsTranslation';
import { PolicyFormSchema } from '../PolicyInformationForm';

type PremiumFinanceUnavailableModalProps = {
  isPfModalOpen: boolean;
  isSubmitting: boolean;
  onClose: () => void;
  handleSuccessfulSubmission: () => void;
  handleFailedSubmission: () => void;
};

export const PremiumFinanceUnavailableModal = ({
  isPfModalOpen,
  isSubmitting,
  onClose,
  handleSuccessfulSubmission,
  handleFailedSubmission,
}: PremiumFinanceUnavailableModalProps): React.ReactElement => {
  const { t } = usePaymentsTranslation();

  const { handleSubmit } = useFormContext<PolicyFormSchema>();
  const submitForm = handleSubmit(
    () => {
      onClose();
      handleSuccessfulSubmission();
    },
    () => {
      onClose();
      handleFailedSubmission();
    },
  );

  return (
    <Alert open={isPfModalOpen} onClose={onClose} type={BrandType.Warning} title={t('PREMIUM_FINANCE_UNAVAILABLE')}>
      <div style={{ maxWidth: '600px' }}>
        <ModalBody>
          <Flex>
            <Row>
              <Col xs={12}>
                <span>{t('PREMIUM_FINANCE_UNAVAILABLE_DESCRIPTION')}</span>
              </Col>
            </Row>
          </Flex>
        </ModalBody>
      </div>
      <ModalFooter>
        <Button type="secondary" onClick={onClose}>
          {t('BACK')}
        </Button>
        <Button type="primary" onClick={submitForm} className="ml-100" isLoading={isSubmitting} disabled={isSubmitting}>
          {t('CONTINUE_TO_PAYMENT')}
        </Button>
      </ModalFooter>
    </Alert>
  );
};
