import { toIntlFormat } from '@appliedsystems/payments-core';
import React from 'react';
import { currencyMap } from '../../constants/constants';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { Locale } from '../../store/Locale';
import { useHppDataStore } from '../HostedPaymentPageContainer/useHppData';
import classes from './AmountBreakdown.module.scss';

export const AmountBreakdown = (): React.ReactElement => {
  const { t } = usePaymentsTranslation();
  const { hppData } = useHppDataStore();
  const { locale } = Locale.useContainer();
  const currencyCode = currencyMap[locale];
  const { pf } = hppData;

  const getIntlFormattedAmount = (amount: number) =>
    toIntlFormat(
      {
        amount,
        currencyCode,
      },
      locale,
    );

  const downPayment = getIntlFormattedAmount(pf?.quote?.quoteId ? pf?.quote?.downPaymentAmount : 0);
  const fee = hppData.paymentMethod ? getIntlFormattedAmount(hppData.paymentFee) : <span>{t('TBD')}</span>;

  const downPaymentAmount = pf?.quote?.downPaymentAmount ?? 0;
  const hasNonfinancedInvoices = pf?.pfSubtotal !== downPaymentAmount;
  const nonFinancedAmount = hasNonfinancedInvoices ? pf?.pfSubtotal! - downPaymentAmount : undefined;

  const totalDue = hppData.paymentMethod
    ? getIntlFormattedAmount(hppData.paymentTotal)
    : getIntlFormattedAmount(pf?.pfSubtotal ?? 0);

  return (
    <>
      <div>
        {t('BREAKDOWN_DESCRIPTION', undefined, {
          totalAmount: totalDue,
        } as any)}
      </div>
      <div className={classes.table}>
        <div className={classes.row}>
          <div className={classes.cell}>
            <strong>{t('DOWNPAYMENT_DESCRIPTION')}</strong>
          </div>
          {hasNonfinancedInvoices && (
            <div className={classes.cell}>
              <strong>{t('NON_FINANCED_AMOUNT')}</strong>
            </div>
          )}
          <div className={classes.cell}>
            <strong>{t('CONVENIENCE_FEE')}</strong>
          </div>
          <div className={classes.cell}>
            <strong>{t('TOTAL_DUE_TODAY')}</strong>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.cell}>{downPayment}</div>
          {hasNonfinancedInvoices && (
            <div className={classes.cell}>{getIntlFormattedAmount(nonFinancedAmount || 0)}</div>
          )}
          <div className={classes.cell}>{fee}</div>
          <div className={classes.cell}>{totalDue}</div>
        </div>
      </div>
    </>
  );
};
