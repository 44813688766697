import { Badge, BadgeShape, BadgeType, H4, ListItem, SimpleList } from '@appliedsystems/applied-design-system';
import { TransactionType } from '@appliedsystems/payments-core';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { currencyMap } from '../../constants/constants';
import { useCheckIfFinanceable } from '../../hooks/useCheckIfFinanceable';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { Locale } from '../../store/Locale';
import { toIntlFormatHelper } from '../../util/getAmountWithFees';
import { PayBySelection } from '../HostedPaymentPageContainer/enums';
import { AvailableTransactionTypes, FullInvoiceGroup } from '../HostedPaymentPageContainer/types';
import { useHppDataStore } from '../HostedPaymentPageContainer/useHppData';
import classes from './SummaryCard.module.scss';

type InvoiceSummaryProps = {
  invoice: FullInvoiceGroup;
  showPill: boolean;
  checkIfFinanceable: (invoice: FullInvoiceGroup) => boolean | undefined;
};

type PaymentAmountDetailType = {
  fee: string;
  subtotal: string;
  total: string;
};

type PaymentDescriptionProps = {
  paymentOption: AvailableTransactionTypes | undefined;
  downPaymentAmount: number;
  paymentDescription: string;
  paymentAmountDetail: PaymentAmountDetailType;
  isConfirmation: boolean;
};

const DescriptionItem = ({ title, value }: { title: string; value: string | number }) => (
  <div className={classes.description}>
    <div>{title}</div>
    <div>{value || '-'}</div>
  </div>
);

const PaidPill = ({ label }: { label: string }) => {
  return (
    <span className={classes.paidPill}>
      <Badge shape={BadgeShape.Pill} type={BadgeType.Success} size="xxs" icon={'NoIcon'}>
        {label}
      </Badge>
    </span>
  );
};

const InvoiceSummary = ({ invoice, showPill, checkIfFinanceable }: InvoiceSummaryProps) => {
  const { t } = usePaymentsTranslation();
  const { locale } = Locale.useContainer();
  const { hppData } = useHppDataStore();
  const { pf } = hppData;
  const badgeType = checkIfFinanceable(invoice) ? BadgeType.Accordion : BadgeType.Applied;
  const badgeText = checkIfFinanceable(invoice) ? t('FINANCING_AVAILABLE') : t('DUE_IN_FULL');
  const badgeIcon = checkIfFinanceable(invoice) ? undefined : 'NoIcon';
  const badgeTestId = checkIfFinanceable(invoice) ? undefined : 'due-in-full-badge';
  return (
    <div key={invoice.invoiceNumber}>
      <div className={classes.invoice}>
        <span className={classes.invoiceTitle}>
          {t('INVOICE')} {invoice.invoiceNumber}{' '}
          {pf?.quote?.quoteId && showPill && (
            <Badge type={badgeType} size="xxs" icon={badgeIcon} testId={badgeTestId}>
              {badgeText}
            </Badge>
          )}
        </span>
      </div>
      {invoice.invoiceItems.map((item) => (
        <div className={classes.invoiceItem}>
          <div>{item.description}</div>
          <div>{toIntlFormatHelper(item.amountDue, currencyMap[locale], locale)}</div>
        </div>
      ))}
    </div>
  );
};

const PaymentDescription = ({
  paymentOption,
  downPaymentAmount,
  paymentDescription,
  paymentAmountDetail,
  isConfirmation,
}: PaymentDescriptionProps) => {
  const { locale } = Locale.useContainer();
  const { t } = usePaymentsTranslation();
  const { hppData } = useHppDataStore();
  const { pf } = hppData;
  if (paymentOption === TransactionType.PREMIUM_FINANCE && pf?.quote?.quoteId) {
    return (
      <>
        <div>{t('DOWN_PAYMENT') || t('DESCRIPTION')}</div>
        {isConfirmation && <PaidPill label={t('PAID')} />}
        <div>{toIntlFormatHelper(downPaymentAmount, currencyMap[locale], locale) || '-'}</div>
      </>
    );
  } else {
    return (
      <>
        <div>{paymentDescription || t('DESCRIPTION')}</div>
        <div>{paymentAmountDetail.subtotal || '-'}</div>
      </>
    );
  }
};

export const PaymentSummary = ({
  shouldDisplayDescription,
  paymentAmountDetail,
  isConfirmation,
}: {
  shouldDisplayDescription: boolean;
  paymentAmountDetail: any;
  isConfirmation: boolean;
}) => {
  const { locale } = Locale.useContainer();
  const { t } = usePaymentsTranslation();
  const { hppData, selectedInvoices } = useHppDataStore();
  const { payBy, paymentDescription, paymentOption, paymentMethod, pf } = hppData;
  const isPremiumFinanceSelected = pf?.quote?.quoteId && paymentOption === TransactionType.PREMIUM_FINANCE;
  const { checkIfFinanceable } = useCheckIfFinanceable();
  const downPaymentAmount = pf?.quote?.quoteId ? pf.quote.downPaymentAmount : 0;

  const getFeeDisplayValue = () => {
    if (hppData.paymentMethod) {
      return paymentAmountDetail.fee;
    } else if (pf?.quote?.quoteId) {
      return 'TBD';
    } else {
      return '-';
    }
  };

  const renderInvoiceTotalsSummary = useMemo(() => {
    if (paymentOption === TransactionType.PREMIUM_FINANCE && pf?.quote?.quoteId) {
      const nonFinanceableInvoiceList =
        selectedInvoices.length &&
        selectedInvoices
          .filter((invoice) => !checkIfFinanceable(invoice))
          .map((invoice) => {
            return (
              <React.Fragment key={invoice.invoiceNumber}>
                <div className={classes.invoice}>
                  <span>
                    {t('INVOICE')} {invoice.invoiceNumber}
                  </span>
                  {isConfirmation && <PaidPill label={t('PAID')} />}
                  <div>{invoice.amountDue}</div>
                </div>
                {invoice.invoiceItems.map((item) => (
                  <div className={classes.invoiceItem} key={item.itemNumber}>
                    <div>{item.description}</div>
                  </div>
                ))}
              </React.Fragment>
            );
          });
      const financeableInvoiceList =
        selectedInvoices.length &&
        selectedInvoices
          .filter((invoice) => checkIfFinanceable(invoice))
          .map((invoice) => {
            return invoice.invoiceItems.map((item) => (
              <div className={classes.invoiceItem} key={item.itemNumber}>
                <div>{item.description}</div>
              </div>
            ));
          });
      return (
        <div>
          {!isConfirmation && (
            <div className={classes.invoice}>
              <span>{t('DOWN_PAYMENT')}</span>
              <div>{toIntlFormatHelper(downPaymentAmount, currencyMap[locale], locale)}</div>
            </div>
          )}
          {financeableInvoiceList}
          {nonFinanceableInvoiceList}
        </div>
      );
    }
    return (
      <>
        {selectedInvoices.length ? (
          selectedInvoices.map((invoice) => {
            return (
              <div key={invoice.invoiceNumber}>
                <div className={classes.invoice}>
                  <span>
                    {t('INVOICE')} {invoice.invoiceNumber}
                  </span>
                  <div>{invoice.amountDue}</div>
                </div>
                {invoice.invoiceItems.map((item) => (
                  <div className={classes.invoiceItem}>
                    <div>{item.description}</div>
                  </div>
                ))}
              </div>
            );
          })
        ) : (
          <div className={classes.description}>
            <div>{t('DESCRIPTION')}</div>
            <div>-</div>
          </div>
        )}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paymentOption,
    pf?.quote?.quoteId,
    selectedInvoices,
    t,
    pf?.quote?.downPaymentAmount,
    locale,
    checkIfFinanceable,
  ]);

  const paymentTotal = useMemo(() => {
    const totalPaid = paymentMethod ? paymentAmountDetail.total : '-';

    // if user selected premium finance as the payment option and no payment method is selected, the show pfSubtotal
    // if user selected premium finance as the payment option and a payment method is selected, show paymentTotal
    // if user selected pay in full, show paymentAmount in the hppData
    const totalDueNow =
      hppData.pf?.pfSubtotal && !paymentMethod
        ? toIntlFormatHelper(hppData.pf?.pfSubtotal, currencyMap[locale], locale)
        : paymentMethod
        ? toIntlFormatHelper(hppData.paymentTotal, currencyMap[locale], locale)
        : toIntlFormatHelper(hppData.paymentAmount, currencyMap[locale], locale);

    return (
      <ListItem>
        <div className={classes.listItem}>
          <H4>
            {t(
              isConfirmation && pf?.quote?.quoteId
                ? 'TOTAL_PAID_TODAY'
                : isConfirmation
                ? 'TOTAL_PAID'
                : 'TOTAL_DUE_NOW',
            )}
          </H4>
          <H4>{paymentOption === TransactionType.PAID_IN_FULL ? totalPaid : totalDueNow}</H4>
        </div>
        {isConfirmation && (
          <div className={classes.listItemSubheading}>
            <span>{t('PAYMENT_METHOD')}</span>
            <span className={classes.paymentMethod}>
              {paymentMethod}{' '}
              {hppData.endDigits &&
                t('ENDING_WITH', undefined, {
                  lastFourDigits: hppData.endDigits,
                } as any)}
            </span>
          </div>
        )}
      </ListItem>
    );
  }, [
    t,
    isConfirmation,
    paymentOption,
    paymentAmountDetail.total,
    locale,
    paymentMethod,
    pf?.quote?.quoteId,
    hppData.endDigits,
    hppData.paymentTotal,
    hppData.paymentAmount,
    hppData.pf?.pfSubtotal,
  ]);

  const InvoiceItems = ({ selectedInvoices }: { selectedInvoices: FullInvoiceGroup[] }) => {
    return (
      <div>
        {selectedInvoices.map((invoice) => {
          if (checkIfFinanceable(invoice)) {
            return (
              <>
                {invoice.invoiceItems.map((item) => (
                  <div className={classes.invoiceItem}>
                    <div>{item.description}</div>
                  </div>
                ))}
              </>
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  };

  return (
    <SimpleList title={t('PAYMENT_SUMMARY')}>
      <hr className={classes.line} />
      <ListItem>
        {shouldDisplayDescription && (
          <div className={classes.description}>
            <PaymentDescription
              paymentOption={paymentOption}
              downPaymentAmount={downPaymentAmount}
              paymentDescription={paymentDescription}
              paymentAmountDetail={paymentAmountDetail}
              isConfirmation={isConfirmation}
            />
          </div>
        )}
        {payBy === PayBySelection.INVOICE && renderInvoiceTotalsSummary}
        <div className={classes.listItem}>
          <span>{t('CONVENIENCE_FEE')}</span>
          <span>{getFeeDisplayValue()}</span>
        </div>
      </ListItem>
      <span>{paymentTotal}</span>
      {isPremiumFinanceSelected && pf.quote && !isConfirmation && (
        <>
          <div className={classes.invoice}>
            <span className={classes.invoiceTitle}>{t('AMOUNT_FINANCED')}</span>
            <span>{toIntlFormatHelper(pf.quote.amountFinanced, currencyMap[locale], locale)}</span>
          </div>
          <InvoiceItems selectedInvoices={selectedInvoices} />
        </>
      )}
    </SimpleList>
  );
};
export const SummaryCard = ({ isConfirmation }: { isConfirmation: boolean }) => {
  const { t } = usePaymentsTranslation();
  const { hppData, selectedInvoices } = useHppDataStore();
  const { locale } = Locale.useContainer();
  const { paymentFee, paymentAmount, payBy, paymentDescription } = hppData;
  const { pathname } = useLocation();
  const isCheckout = pathname.includes('checkoutv2');
  const { checkIfFinanceable } = useCheckIfFinanceable();

  const paymentAmountDetail = useMemo(() => {
    const currencyCode = currencyMap[locale];
    return {
      fee: toIntlFormatHelper(paymentFee, currencyCode, locale),
      subtotal: toIntlFormatHelper(paymentAmount, currencyCode, locale),
      total: toIntlFormatHelper(hppData.paymentTotal, currencyCode, locale),
    };
  }, [paymentAmount, paymentFee, hppData.paymentTotal, locale]);

  const renderInvoiceSummary = useMemo(() => {
    return selectedInvoices.length ? (
      selectedInvoices.map((invoice) => (
        <InvoiceSummary
          key={invoice.invoiceNumber}
          invoice={invoice}
          checkIfFinanceable={checkIfFinanceable}
          showPill={!isConfirmation}
        />
      ))
    ) : (
      <div className={classes.description}>
        <div>{t('DESCRIPTION')}</div>
        <div>-</div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInvoices, t, checkIfFinanceable]);

  const hideDescription = isCheckout && !paymentDescription;

  const shouldDisplayDescription = payBy !== PayBySelection.INVOICE && !hideDescription;

  return (
    <article className={classes.summaryCard}>
      <SimpleList title={t(isConfirmation ? 'PAYMENT_SUMMARY' : 'ORDER_SUMMARY')}>
        <hr className={classes.line} />
        {payBy === PayBySelection.INVOICE && <ListItem>{renderInvoiceSummary}</ListItem>}
        {shouldDisplayDescription && (
          <ListItem>
            <DescriptionItem title={paymentDescription || t('DESCRIPTION')} value={paymentAmountDetail.subtotal} />
          </ListItem>
        )}
        <ListItem>
          <DescriptionItem title={t('SUBTOTAL')} value={paymentAmountDetail.subtotal} />
          {isConfirmation && (
            <div className={classes.listItem}>
              <span>{t('CONVENIENCE_FEE')}</span>
              <span>{paymentAmountDetail.fee}</span>
            </div>
          )}
        </ListItem>
      </SimpleList>
      {!isConfirmation && (
        <PaymentSummary
          shouldDisplayDescription={shouldDisplayDescription}
          paymentAmountDetail={paymentAmountDetail}
          isConfirmation={isConfirmation}
        />
      )}
    </article>
  );
};
