import { format, utcToZonedTime } from 'date-fns-tz';

export const toLocaleDateTimeString = (date: string | Date | null | undefined, defaultValue?: string) => {
  if (!date) {
    return defaultValue;
  }
  const _date = new Date(date);
  const localTime = utcToZonedTime(_date, Intl.DateTimeFormat().resolvedOptions().timeZone);
  const localTimeString = format(localTime, 'P p zzz');
  return localTimeString;
};

export const toLocaleDateString = (date: string | Date | null | undefined, defaultValue?: string) => {
  if (!date) {
    return defaultValue;
  }
  const _date = new Date(date);
  const localTime = utcToZonedTime(_date, Intl.DateTimeFormat().resolvedOptions().timeZone);
  return format(localTime, 'P');
};

export const toDateStringNoTz = (date: string | Date | null | undefined) => {
  if (!date) {
    return '';
  }

  let _date;
  if (typeof date === 'string') {
    const parts = date.split(' ')[0].split('-');
    _date = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
  } else {
    _date = date;
  }

  return format(_date, 'P');
};
