import { Button, Label, SectionTypes, SectionV2 } from '@appliedsystems/applied-design-system';
import { premiumFinanceProviderLabels } from '@appliedsystems/payments-core';
import React from 'react';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { Locale } from '../../store/Locale';
import { AddESignModal } from '../ESign/ESignModal';
import { PfInfo } from '../HostedPaymentPageContainer/types';
import { PremiumFinanceQuoteInformation } from '../PremiumFinanceQuoteInformation/PremiumFinanceQuoteInformation';
import { SetUpPaymentInstallmentsModal } from '../SetUpPaymentInstallmentsModal/SetUpPaymentInstallmentsModal';
import greenCheck from './check-circle.svg';
import classes from './PaymentOptionDetails.module.scss';

type Props = {
  pfInfo: PfInfo;
  isPfaSigned: boolean;
  setIsPfaSigned: (isSigned: boolean) => void;
};

export const PaymentOptionDetails = ({ pfInfo, isPfaSigned, setIsPfaSigned }: Props): React.ReactElement => {
  const { t } = usePaymentsTranslation();
  const { locale } = Locale.useContainer();

  const [isOpenEsignModal, setIsOpenEsignModal] = React.useState(false);
  const [isSetUpPaymentInstallmentsModalOpen, setIsSetUpPaymentInstallmentsModalOpen] = React.useState(false);

  return (
    <>
      <SectionV2 title={t('PREMIUM_FINANCE_AGREEMENT')} type={SectionTypes.H3}>
        <div className="mb-100">
          <span>{t('REVIEW_AND_SIGN_DESCRIPTION')}</span>
        </div>

        <Label required>{t('PREMIUM_FINANCE_AGREEMENT')}</Label>
        <Button type="secondary" icon="PencilIcon" onClick={() => setIsOpenEsignModal(true)}>
          {t('REVIEW_AND_SIGN_BUTTON')}
        </Button>

        {isOpenEsignModal && pfInfo.quote?.base64Pfa && (
          <AddESignModal
            isOpenEsignModal={isOpenEsignModal}
            onClose={() => setIsOpenEsignModal(false)}
            base64Pfa={
              pfInfo.signeeInfo?.signedBase64Pfa && pfInfo.signeeInfo?.signedBase64Pfa.trim() !== ''
                ? pfInfo.signeeInfo?.signedBase64Pfa
                : pfInfo.quote.base64Pfa
            }
            isPfaSigned={isPfaSigned}
            setIsPfaSigned={setIsPfaSigned}
            signeeName={pfInfo.signeeInfo?.name || ''}
            signeePhoneNumber={pfInfo.signeeInfo?.phoneNumber || ''}
          />
        )}
      </SectionV2>
      {isPfaSigned && (
        <div className={classes.successIcon}>
          <img src={greenCheck} alt={t('CONFIRMATION_SUCCESS_ALT_TEXT')} />
          <div className={classes.successText}>
            <span>{t('SIGNED_SUCCESSFULLY')}</span>
          </div>
        </div>
      )}
      <SectionV2 title={t('FINANCING_SUMMARY_TITLE')} type={SectionTypes.H3}>
        <div className="mb-100">
          <span>
            {t('FINANCING_SUMMARY_DESCRIPTION', undefined, {
              company: pfInfo.quote?.company ? premiumFinanceProviderLabels[pfInfo.quote?.company] : '',
            } as any)}
          </span>
        </div>
        {pfInfo.quote?.pfpAcceptsAchInfo && (
          <>
            <Button
              type="secondary"
              className="mb-200"
              onClick={() => setIsSetUpPaymentInstallmentsModalOpen(true)}
              disabled={!isPfaSigned}
            >
              {t('SET_UP_PAYMENTS_INSTALLMENTS')}
            </Button>
            <SetUpPaymentInstallmentsModal
              isSetUpPaymentInstallmentsModalOpen={isSetUpPaymentInstallmentsModalOpen}
              onClose={() => setIsSetUpPaymentInstallmentsModalOpen(false)}
            />
          </>
        )}
        <PremiumFinanceQuoteInformation locale={locale} pfInfo={pfInfo} />
      </SectionV2>
    </>
  );
};
