import { ErrorCode, TransactionType } from '@appliedsystems/payments-core';
import { datadogLogs } from '@datadog/browser-logs';
import { useCallback } from 'react';
import { ErrorMessage } from 'src/components/ErrorAlert/ErrorAlert';
import { HppData } from 'src/components/HostedPaymentPageContainer/types';
import { HppSession } from 'src/components/HostedPaymentPageContainer/useHppSession';
import { ApiClient } from '../api/ApiClient';
import { MAP_REFUSED_CODE_TO_TRANSLATION_KEY } from '../reducers/useCheckoutReducer';
import { AdyenCheckoutState, generateIdempotencyKey } from '../util/adyen';

// todo: there's no point to this being a hook right now since it has
//  no internal state- make it a regular exported function an place it
//  somewhere more fitting + we can avoid another changing useCallback ref
export const useMakePayment = (setErrorMessage: (errorMessage?: ErrorMessage) => void) => {
  const makePayment = useCallback(
    async (
      onSuccessCallback: (state: AdyenCheckoutState) => void,
      paymentMethod: any,
      recaptchaToken: string,
      state: AdyenCheckoutState,
      hppData: HppData,
      storePaymentMethod?: boolean,
      hppSessionDetail?: HppSession | null,
    ) => {
      const setUnknownErrorMessage = (code: number) => {
        setErrorMessage(['UNEXPECTED_ERROR_CODE', { code: `${code}` }]);
      };

      setErrorMessage();

      if (!hppSessionDetail?.paymentFlowSessionId || !hppSessionDetail?.pspSessionId) {
        setErrorMessage(['PAYMENT_FLOW_SESSION_ID_MISSING']);
        return false;
      }

      const idempotencyKey = generateIdempotencyKey();
      datadogLogs.logger.info('PSP session and idemPotency key for request', {
        pspSessionId: hppSessionDetail.sessionId,
        idempotencyKey: idempotencyKey,
      });

      try {
        const response = await ApiClient.getInstance(hppSessionDetail.paymentFlowSessionId).completePayment({
          pspSessionId: hppSessionDetail?.sessionId,
          idempotencyKey,
          paymentMethod,
          recaptchaToken: recaptchaToken,
          storePaymentMethod,
          pf:
            hppData.paymentOption === TransactionType.PREMIUM_FINANCE && hppData.pf?.quote && hppData.pf?.signeeInfo
              ? {
                  quoteId: hppData.pf.quote.quoteId,
                  unsignedPfa: hppData.pf.quote.base64Pfa,
                  bankAccountNumber: hppData.pf.accountInformation?.authorizePaymentInstallments
                    ? String(hppData.pf.accountInformation.accountNumber)
                    : undefined,
                  bankRoutingNumber: hppData.pf.accountInformation?.authorizePaymentInstallments
                    ? String(hppData.pf.accountInformation.abaRoutingNumber)
                    : undefined,
                  insuredSignature: {
                    name: hppData.pf.signeeInfo.name,
                    phone: hppData.pf.signeeInfo.phoneNumber,
                    email: hppData.userEmail,
                    dateTime: hppData.pf.signeeInfo.timeStamp,
                  },
                  financeableTransactions: hppData.pf.quote.financeableTransactions,
                }
              : undefined,
        });

        if (response.status !== 'ok') {
          const errorCode = response.additionalDetails?.[0]?.errorCode;
          if (errorCode) {
            switch (errorCode) {
              case ErrorCode.PaymentAmountTooLow:
                setErrorMessage(['ERROR_FEE_AMOUNT_BELOW_MINIMUM']);
                break;
              case ErrorCode.ACHValidationFailed:
                setErrorMessage(['ERROR_ACH_VALIDATION_FAILED']);
                break;
              case ErrorCode.BankAccountNotValid:
                setErrorMessage(['ERROR_BANK_ACCOUNT_NOT_VALID']);
                break;
              case ErrorCode.DuplicateCompletePaymentAttempt:
                setErrorMessage(['ERROR_DUPLICATE_PAYMENT_ATTEMPT']);
                break;
              case ErrorCode.RecaptchaBrowserError:
                setErrorMessage(['RECAPTCHA_BROWSER_ERROR', { id: response.traceId }]);
                break;
              default:
                console.error('Complete Payment Error Unknown Error Code ' + errorCode, response);
                setUnknownErrorMessage(1);
                break;
            }
          } else {
            if (response.type === 'network' || response.status > 500) setErrorMessage(['ERROR_MAKE_PAYMENT_NETWORK']);
            else if (response.status === 500)
              setErrorMessage(['ERROR_MAKE_PAYMENT_INTERNAL', { id: response.traceId }]);
            else setErrorMessage(['ERROR_MAKE_PAYMENT_UNKNOWN', { id: response.traceId }]);
          }
          return false;
        }

        switch (response.data!.resultCode) {
          case 'Authorised':
            onSuccessCallback(state);
            return true;
          case 'Error':
            console.error('Complete Payment Error Result', response);
            setUnknownErrorMessage(3);
            break;
          case 'Refused':
            console.error('Complete Payment Error refused', response);
            const refusedCode = response.data!.refusalReasonCode;
            const refusedMessage = MAP_REFUSED_CODE_TO_TRANSLATION_KEY[refusedCode!];
            if (refusedMessage) setErrorMessage([refusedMessage]);
            else if (response.data!.refusalReason)
              setErrorMessage(['REFUSED_ERROR_REASON', { refusalReason: response.data!.refusalReason }]);
            else {
              console.error(`Unknown refusalCode (${refusedCode}) + refusalReason (${response.data?.refusalReason})`);
              setUnknownErrorMessage(4);
            }
            break;
          default:
            console.error('Complete Payment Error unknown resultCode ' + response.data!.resultCode, response);
            setUnknownErrorMessage(5);
            break;
        }
        return false;
      } catch (err: unknown) {
        console.error('Complete Payment Error unknown', err);
        setErrorMessage(['ERROR_MAKE_PAYMENT_UNKNOWN']);
        return false;
      }
    },
    [setErrorMessage],
  );
  return { makePayment };
};
